import IconFont from './icon-font';

function LabelTitle() {
  return (
    <div className="flex items-center justify-center text-base fixed top-4 left-1/2 -translate-x-1/2 z-50">
      <span className="mr-2">🎉</span>
      <span className="mr-2">本平台基于</span>
      <span className="mr-2 bg-gradient-to-l from-[#FF6B97] to-[#835EFF] bg-clip-text text-transparent font-bold">
        大模型数据标注平台 LabelLLM 构建
      </span>
      <span className="mr-6 inline-block text-white text-xs bg-gradient-to-l from-[#FF6B97] to-[#7045FF] rounded-full leading-5 px-2">
        开源
      </span>
      <a
        href="https://github.com/opendatalab/LabelLLM"
        target="_blank"
        className="font-bold inline-flex items-center"
        rel="noreferrer"
      >
        <span>立即前往</span>
        <IconFont className="font-bold text-lg" type="icon-gengduoshujufenxi" />
      </a>
    </div>
  );
}

export default LabelTitle;
